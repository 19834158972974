import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { LogoLink } from "components/headers/light.js";
import logo from "images/custom/oneomirlogo_small.png";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;

export default () => {
  return (
    <NavRow>
      <LogoLink href="/">
        <img src={logo} alt="" />
        One Omir Software
      </LogoLink>
      {/* <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
        <NavLink target="_blank" href="https://owaiskhan.me/post/free-tailwindcss-react-ui-kit">
          License & Usage
        </NavLink>
        <NavLink target="_blank" href="https://owaiskhan.me">
          Who Am I ?
        </NavLink>
        <NavLink target="_blank" href="https://twitter.com/owaiswiz">
          Twitter
        </NavLink>
        <NavLink target="_blank" href="mailto:owaiswiz@gmail.com">
          Hire Me!
        </NavLink>
        <div tw="md:hidden flex-100 h-0"></div>
        <PrimaryNavLink target="_blank" href="https://gum.co/QaruQ">
          Download Now
        </PrimaryNavLink>
      </div> */}
    </NavRow>
  );
};