import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import dayjs  from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);

/* Hero */
const FullPage = tw.div`min-h-screen min-w-full bg-gray-900`;
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;

const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto`}
`;

const Column = styled.div`
  ${tw`md:w-1/3 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-3 py-6 border-2 border-dashed border-white rounded-lg mt-6`}
  min-width: 190px;
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-2xl leading-none text-white`}
  }

  .main {
    ${tw`mt-3 font-semibold text-primary-200 text-4xl leading-loose`}
  }
  .secondary {
    ${tw`mt-3 font-semibold text-primary-200 text-xl leading-loose`}
  }
`;


export default () => {
  const nextSeasonDate = new Date(2022, 4, 24);
  let { currentTime, setCurrentTime } = useState(new Date());
  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date());
    }, 1000 * 60 * 60);
  }, []);
  return (
    <FullPage>
      <Row>
        <TwoColumnContainer>
          <Column>
            <Card href="/privacy-policy">
              <span className="textContainer">
                <span className="title">Next Season</span>
                <p className="main">
                  {dayjs(nextSeasonDate).format("D MMM")}
                </p>
                {/* <p className="secondary">
                  Sprints left: {dayjs(new Date()).diff(dayjs(nextSeasonDate), "week")}
                </p>
                <p className="secondary">
                  Days left: {dayjs(new Date()).diff(dayjs(nextSeasonDate), "day")}
                </p> */}
              </span>
            </Card>
          </Column>
          <Column>
            <Card href="/privacy-policy">
              <span className="textContainer">
                <span className="title">Sprints left</span>
                <p className="main">
                  {dayjs(nextSeasonDate).diff(dayjs(currentTime), "week")}
                </p>
              </span>
            </Card>
          </Column>
          <Column>
            <Card href="/privacy-policy">
              <span className="textContainer">
                <span className="title">Days left</span>
                <p className="main">
                  {dayjs(nextSeasonDate).businessDiff(dayjs(currentTime))}
                </p>
              </span>
            </Card>
          </Column>
        </TwoColumnContainer>
      </Row>
    </FullPage>
  );
};