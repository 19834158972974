import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl, Content2Xl } from "components/misc/Layouts";
import { LogoLink } from "components/headers/light.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import tw from "twin.macro";
import styled from "styled-components";

import Nav from "custom/Nav.js";

const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const Content = tw.div`mt-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  .lst-kix_fl5tgqqoyc2d-8 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-8;
      }
      ol.lst-kix_fl5tgqqoyc2d-7.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-7 0;
      }
      .lst-kix_19gmnee74sij-7 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-7, lower-roman) ". ";
      }
      ol.lst-kix_vg07ombeoyvo-3.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-3 0;
      }
      ol.lst-kix_i5ei47vkpwoc-2.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-2 0;
      }
      .lst-kix_19gmnee74sij-6 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-6, lower-latin) ". ";
      }
      .lst-kix_19gmnee74sij-3 > li:before {
        content: "(" counter(lst-ctn-kix_19gmnee74sij-3, lower-latin) ") ";
      }
      .lst-kix_19gmnee74sij-5 > li:before {
        content: "(" counter(lst-ctn-kix_19gmnee74sij-5, decimal) ") ";
      }
      .lst-kix_kn8ag28tizsn-0 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-0;
      }
      .lst-kix_19gmnee74sij-4 > li:before {
        content: "(" counter(lst-ctn-kix_19gmnee74sij-4, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-3 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-3;
      }
      ol.lst-kix_gs5dka6k8n6a-2.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-2 0;
      }
      ol.lst-kix_ahmacfe5zsdf-2.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-2 0;
      }
      .lst-kix_19gmnee74sij-1 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-1;
      }
      ol.lst-kix_19gmnee74sij-8.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-8 0;
      }
      .lst-kix_vg07ombeoyvo-7 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-7;
      }
      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0;
      }
      .lst-kix_19gmnee74sij-8 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-8, decimal) ". ";
      }
      .lst-kix_gs5dka6k8n6a-2 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-2;
      }
      .lst-kix_fl5tgqqoyc2d-0 > li:before {
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-0, lower-latin) ") ";
      }
      ol.lst-kix_i5ei47vkpwoc-8.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-8 0;
      }
      .lst-kix_fl5tgqqoyc2d-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-1, lower-roman) ") ";
      }
      .lst-kix_kn8ag28tizsn-2 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-2;
      }
      .lst-kix_i5ei47vkpwoc-7 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-7;
      }
      .lst-kix_ahmacfe5zsdf-5 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-5;
      }
      .lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2;
      }
      .lst-kix_gs5dka6k8n6a-4 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-4;
      }
      ol.lst-kix_gs5dka6k8n6a-8.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-8 0;
      }
      ol.lst-kix_kn8ag28tizsn-8.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-8 0;
      }
      .lst-kix_19gmnee74sij-3 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-3;
      }
      .lst-kix_vg07ombeoyvo-3 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-3;
      }
      .lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4;
      }
      .lst-kix_i5ei47vkpwoc-0 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-0;
      }
      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0;
      }
      .lst-kix_kn8ag28tizsn-4 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-4;
      }
      .lst-kix_fl5tgqqoyc2d-4 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-4;
      }
      ol.lst-kix_19gmnee74sij-3.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-3 0;
      }
      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0;
      }
      .lst-kix_gs5dka6k8n6a-8 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-8;
      }
      ol.lst-kix_i5ei47vkpwoc-3.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-3 0;
      }
      ol.lst-kix_19gmnee74sij-2.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-2 0;
      }
      .lst-kix_19gmnee74sij-1 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-1, lower-roman) ") ";
      }
      .lst-kix_19gmnee74sij-2 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-2, decimal) ") ";
      }
      ol.lst-kix_gs5dka6k8n6a-3.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-3 0;
      }
      .lst-kix_19gmnee74sij-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        ${tw`text-sm  text-gray-800`}(lst-ctn-kix_19gmnee74sij-0, lower-latin) ") ";
      }
      ol.lst-kix_list_1-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-6 {
        list-style-type: none;
      }
      .lst-kix_fl5tgqqoyc2d-2 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-2;
      }
      ol.lst-kix_kn8ag28tizsn-4.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-4 0;
      }
      .lst-kix_kn8ag28tizsn-6 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-6;
      }
      ol.lst-kix_list_1-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-1 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-6.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-6 0;
      }
      ol.lst-kix_list_1-2 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-1.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-1 0;
      }
      .lst-kix_i5ei47vkpwoc-4 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-4;
      }
      .lst-kix_kn8ag28tizsn-7 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-7;
      }
      ol.lst-kix_kn8ag28tizsn-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-8 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-3 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-2 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-1 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-4.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-4 0;
      }
      ol.lst-kix_kn8ag28tizsn-0 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-7 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-6.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-6 0;
      }
      ol.lst-kix_kn8ag28tizsn-6 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-5 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-4 {
        list-style-type: none;
      }
      .lst-kix_i5ei47vkpwoc-5 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-5;
      }
      .lst-kix_kn8ag28tizsn-5 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-5;
      }
      .lst-kix_ahmacfe5zsdf-8 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-8;
      }
      .lst-kix_ahmacfe5zsdf-0 > li {
        ${tw`text-sm  text-gray-800`}
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-0;
      }
      ol.lst-kix_ahmacfe5zsdf-3.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-3 0;
      }
      .lst-kix_vg07ombeoyvo-1 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-1;
      }
      ol.lst-kix_kn8ag28tizsn-1.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-1 0;
      }
      .lst-kix_gs5dka6k8n6a-7 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-7;
      }
      .lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8;
      }
      ol.lst-kix_vg07ombeoyvo-1.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-1 0;
      }
      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0;
      }
      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0;
      }
      .lst-kix_19gmnee74sij-6 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-6;
      }
      ol.lst-kix_i5ei47vkpwoc-1.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-1 0;
      }
      ol.lst-kix_19gmnee74sij-5 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-0, lower-latin) ") ";
      }
      ol.lst-kix_19gmnee74sij-4 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-7 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-6 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-1 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-2, decimal) ") ";
      }
      ol.lst-kix_19gmnee74sij-0 {
        list-style-type: none;
      }
      .lst-kix_ahmacfe5zsdf-7 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-7;
      }
      ol.lst-kix_19gmnee74sij-3 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-2.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-2 0;
      }
      ol.lst-kix_19gmnee74sij-2 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-6 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-6;
      }
      ol.lst-kix_19gmnee74sij-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0;
      }
      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0;
      }
      .lst-kix_fl5tgqqoyc2d-5 > li:before {
        content: "(" counter(lst-ctn-kix_fl5tgqqoyc2d-5, decimal) ") ";
      }
      .lst-kix_19gmnee74sij-5 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-5;
      }
      ol.lst-kix_ahmacfe5zsdf-0.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-0 0;
      }
      ol.lst-kix_gs5dka6k8n6a-7.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-7 0;
      }
      .lst-kix_fl5tgqqoyc2d-3 > li:before {
        content: "(" counter(lst-ctn-kix_fl5tgqqoyc2d-3, lower-latin) ") ";
      }
      ol.lst-kix_i5ei47vkpwoc-0.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-0 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-8.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-8 0;
      }
      .lst-kix_list_1-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-1, decimal) ". ";
      }
      ol.lst-kix_kn8ag28tizsn-3.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-3 0;
      }
      .lst-kix_list_1-3 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
      }
      ol.lst-kix_vg07ombeoyvo-2.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-2 0;
      }
      .lst-kix_list_1-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-7, decimal) ". ";
      }
      .lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3;
      }
      .lst-kix_list_1-5 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-5, decimal) ". ";
      }
      .lst-kix_fl5tgqqoyc2d-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-7, lower-roman) ". ";
      }
      .lst-kix_gs5dka6k8n6a-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-8, decimal) ". ";
      }
      .lst-kix_fl5tgqqoyc2d-3 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-3;
      }
      .lst-kix_gs5dka6k8n6a-0 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-0;
      }
      .lst-kix_gs5dka6k8n6a-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-8 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-8;
      }
      .lst-kix_gs5dka6k8n6a-4 > li:before {
        content: "(" counter(lst-ctn-kix_gs5dka6k8n6a-4, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-1 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-1;
      }
      .lst-kix_vg07ombeoyvo-2 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-2;
      }
      ol.lst-kix_ahmacfe5zsdf-1.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-1 0;
      }
      .lst-kix_ahmacfe5zsdf-5 > li:before {
        content: "(" counter(lst-ctn-kix_ahmacfe5zsdf-5, decimal) ") ";
      }
      .lst-kix_ahmacfe5zsdf-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-7, lower-roman) ". ";
      }
      .lst-kix_kn8ag28tizsn-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-8, decimal) ". ";
      }
      .lst-kix_ahmacfe5zsdf-4 > li:before {
        content: "(" counter(lst-ctn-kix_ahmacfe5zsdf-4, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-8, decimal) ". ";
      }
      .lst-kix_gs5dka6k8n6a-3 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-3;
      }
      .lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1;
      }
      .lst-kix_ahmacfe5zsdf-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-1, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-3 > li:before {
        content: "(" counter(lst-ctn-kix_ahmacfe5zsdf-3, lower-latin) ") ";
      }
      .lst-kix_19gmnee74sij-2 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-2;
      }
      .lst-kix_vg07ombeoyvo-6 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-6;
      }
      .lst-kix_ahmacfe5zsdf-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-2, decimal) ") ";
      }
      .lst-kix_kn8ag28tizsn-1 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-1;
      }
      .lst-kix_kn8ag28tizsn-4 > li:before {
        content: "(" counter(lst-ctn-kix_kn8ag28tizsn-4, lower-roman) ") ";
      }
      .lst-kix_kn8ag28tizsn-3 > li:before {
        content: "(" counter(lst-ctn-kix_kn8ag28tizsn-3, lower-latin) ") ";
      }
      .lst-kix_kn8ag28tizsn-5 > li:before {
        content: "(" counter(lst-ctn-kix_kn8ag28tizsn-5, decimal) ") ";
      }
      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0;
      }
      .lst-kix_ahmacfe5zsdf-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-0, lower-latin) ") ";
      }
      .lst-kix_i5ei47vkpwoc-8 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-8;
      }
      ol.lst-kix_ahmacfe5zsdf-5.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-5 0;
      }
      .lst-kix_fl5tgqqoyc2d-7 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-7;
      }
      .lst-kix_kn8ag28tizsn-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-7, lower-roman) ". ";
      }
      .lst-kix_kn8ag28tizsn-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-6, lower-latin) ". ";
      }
      ol.lst-kix_i5ei47vkpwoc-5.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-5 0;
      }
      ol.lst-kix_vg07ombeoyvo-0.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-0 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-8 {
        list-style-type: none;
      }
      .lst-kix_19gmnee74sij-0 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-0;
      }
      ol.lst-kix_fl5tgqqoyc2d-7 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-6 {
        list-style-type: none;
      }
      .lst-kix_vg07ombeoyvo-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-8, decimal) ". ";
      }
      .lst-kix_vg07ombeoyvo-3 > li:before {
        content: "(" counter(lst-ctn-kix_vg07ombeoyvo-3, lower-latin) ") ";
      }
      .lst-kix_vg07ombeoyvo-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-7, lower-roman) ". ";
      }
      ol.lst-kix_fl5tgqqoyc2d-1 {
        list-style-type: none;
      }
      .lst-kix_ahmacfe5zsdf-2 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-2;
      }
      ol.lst-kix_fl5tgqqoyc2d-0 {
        list-style-type: none;
      }
      .lst-kix_kn8ag28tizsn-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-1, lower-roman) ") ";
      }
      ol.lst-kix_kn8ag28tizsn-5.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-5 0;
      }
      .lst-kix_vg07ombeoyvo-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-2, decimal) ") ";
      }
      .lst-kix_kn8ag28tizsn-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-2, decimal) ") ";
      }
      ol.lst-kix_fl5tgqqoyc2d-5 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-4 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-0.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-0 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-3 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-5.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-5 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-2 {
        list-style-type: none;
      }
      .lst-kix_vg07ombeoyvo-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-1, lower-roman) ") ";
      }
      .lst-kix_kn8ag28tizsn-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-0, lower-latin) ") ";
      }
      .lst-kix_vg07ombeoyvo-4 > li:before {
        content: "(" counter(lst-ctn-kix_vg07ombeoyvo-4, lower-roman) ") ";
      }
      .lst-kix_vg07ombeoyvo-5 > li:before {
        content: "(" counter(lst-ctn-kix_vg07ombeoyvo-5, decimal) ") ";
      }
      ol.lst-kix_ahmacfe5zsdf-4.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-4 0;
      }
      .lst-kix_i5ei47vkpwoc-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-2, decimal) ") ";
      }
      .lst-kix_i5ei47vkpwoc-3 > li:before {
        content: "(" counter(lst-ctn-kix_i5ei47vkpwoc-3, lower-latin) ") ";
      }
      .lst-kix_fl5tgqqoyc2d-5 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-5;
      }
      .lst-kix_i5ei47vkpwoc-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-1, lower-roman) ") ";
      }
      .lst-kix_i5ei47vkpwoc-5 > li:before {
        content: "(" counter(lst-ctn-kix_i5ei47vkpwoc-5, decimal) ") ";
      }
      ol.lst-kix_gs5dka6k8n6a-4.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-4 0;
      }
      .lst-kix_19gmnee74sij-4 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-4;
      }
      ol.lst-kix_fl5tgqqoyc2d-5.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-5 0;
      }
      .lst-kix_i5ei47vkpwoc-4 > li:before {
        content: "(" counter(lst-ctn-kix_i5ei47vkpwoc-4, lower-roman) ") ";
      }
      .lst-kix_gs5dka6k8n6a-1 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-1;
      }
      .lst-kix_ahmacfe5zsdf-6 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-6;
      }
      .lst-kix_vg07ombeoyvo-4 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-4;
      }
      ol.lst-kix_vg07ombeoyvo-5.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-5 0;
      }
      .lst-kix_i5ei47vkpwoc-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-0, lower-latin) ") ";
      }
      ol.lst-kix_gs5dka6k8n6a-5 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-4 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-5 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-5;
      }
      ol.lst-kix_gs5dka6k8n6a-7 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-6 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-1 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-6 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-0 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-7 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-3 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-8 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-2 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-2 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-3 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-4 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-5 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-8 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-0 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-1 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-0.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-0 0;
      }
      ol.lst-kix_kn8ag28tizsn-6.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-6 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-4.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-4 0;
      }
      ol.lst-kix_vg07ombeoyvo-6.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-6 0;
      }
      .lst-kix_i5ei47vkpwoc-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-6, lower-latin) ". ";
      }
      .lst-kix_i5ei47vkpwoc-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-7, lower-roman) ". ";
      }
      .lst-kix_i5ei47vkpwoc-3 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-3;
      }
      .lst-kix_i5ei47vkpwoc-6 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-6;
      }
      .lst-kix_i5ei47vkpwoc-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-8, decimal) ". ";
      }
      .lst-kix_ahmacfe5zsdf-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-0 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-0;
      }
      ol.lst-kix_fl5tgqqoyc2d-0.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-0 0;
      }
      ol.lst-kix_vg07ombeoyvo-0 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-1 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-2 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-3 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-4 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-4.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-4 0;
      }
      ol.lst-kix_vg07ombeoyvo-5 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-6 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-7 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-8 {
        list-style-type: none;
      }
      .lst-kix_19gmnee74sij-7 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-7;
      }
      .lst-kix_19gmnee74sij-8 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-8;
      }
      .lst-kix_fl5tgqqoyc2d-1 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-1;
      }
      ol.lst-kix_i5ei47vkpwoc-8 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-7.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-7 0;
      }
      ol.lst-kix_i5ei47vkpwoc-6 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-3.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-3 0;
      }
      ol.lst-kix_i5ei47vkpwoc-7 {
        list-style-type: none;
      }
      .lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7;
      }
      ol.lst-kix_i5ei47vkpwoc-4 {
        list-style-type: none;
      }
      ol.lst-kix_i5ei47vkpwoc-5 {
        list-style-type: none;
      }
      .lst-kix_kn8ag28tizsn-8 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-8;
      }
      .lst-kix_i5ei47vkpwoc-2 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-2;
      }
      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0;
      }
      .lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5;
      }
      ol.lst-kix_vg07ombeoyvo-7.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-7 0;
      }
      ol.lst-kix_i5ei47vkpwoc-4.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-4 0;
      }
      ol.lst-kix_ahmacfe5zsdf-6.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-6 0;
      }
      ol.lst-kix_19gmnee74sij-7.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-7 0;
      }
      .lst-kix_gs5dka6k8n6a-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-1, lower-roman) ") ";
      }
      ol.lst-kix_i5ei47vkpwoc-7.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-7 0;
      }
      .lst-kix_gs5dka6k8n6a-3 > li:before {
        content: "(" counter(lst-ctn-kix_gs5dka6k8n6a-3, lower-latin) ") ";
      }
      .lst-kix_kn8ag28tizsn-3 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-3;
      }
      ol.lst-kix_19gmnee74sij-6.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-6 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-2.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-2 0;
      }
      ol.lst-kix_gs5dka6k8n6a-1.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-1 0;
      }
      .lst-kix_i5ei47vkpwoc-1 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-1;
      }
      ol.lst-kix_ahmacfe5zsdf-7.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-7 0;
      }
      .lst-kix_fl5tgqqoyc2d-0 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-0;
      }
      .lst-kix_fl5tgqqoyc2d-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-0, lower-latin) ") ";
      }

      .lst-kix_list_1-0.h2 > li:before {
        ${tw`text-2xl font-bold`}
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      .lst-kix_list_1-0.h3 > li:before {
        ${tw`text-xl font-bold`}
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      .lst-kix_list_1-0.span > li:before {
        ${tw`text-lg font-bold`}
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      ol.lst-kix_vg07ombeoyvo-8.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-8 0;
      }
      .lst-kix_fl5tgqqoyc2d-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-2, decimal) ") ";
      }
      .lst-kix_fl5tgqqoyc2d-4 > li:before {
        content: "(" counter(lst-ctn-kix_fl5tgqqoyc2d-4, lower-roman) ") ";
      }
      .lst-kix_list_1-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-2, decimal) ". ";
      }
      ol.lst-kix_i5ei47vkpwoc-2 {
        list-style-type: none;
      }
      ol.lst-kix_i5ei47vkpwoc-3 {
        list-style-type: none;
      }
      .lst-kix_list_1-4 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-4, decimal) ". ";
      }
      ol.lst-kix_19gmnee74sij-5.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-5 0;
      }
      ol.lst-kix_i5ei47vkpwoc-0 {
        list-style-type: none;
      }
      ol.lst-kix_i5ei47vkpwoc-1 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-0.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-0 0;
      }
      .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0;
      }
      .lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6;
      }
      ol.lst-kix_ahmacfe5zsdf-8.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-8 0;
      }
      .lst-kix_list_1-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
      }
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }
      ol.lst-kix_fl5tgqqoyc2d-1.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-1 0;
      }
      .lst-kix_fl5tgqqoyc2d-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-8, decimal) ". ";
      }
      .lst-kix_fl5tgqqoyc2d-6 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-6;
      }
      .lst-kix_gs5dka6k8n6a-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-7, lower-roman) ". ";
      }
      ol.lst-kix_i5ei47vkpwoc-6.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-6 0;
      }
      .lst-kix_gs5dka6k8n6a-5 > li:before {
        content: "(" counter(lst-ctn-kix_gs5dka6k8n6a-5, decimal) ") ";
      }
      .lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, decimal) ". ";
      }
      .lst-kix_ahmacfe5zsdf-4 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-4;
      }
      .lst-kix_vg07ombeoyvo-5 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-5;
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c0 {
        margin-left: 37pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c3 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c5 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c4 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 10.5pt;
      }
      .c12 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10.5pt;
        font-style: normal;
      }
      .c13 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 10.5pt;
      }
      .c7 {
        padding-top: 0pt;
        text-indent: 36pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c11 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c8 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c16 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal;
      }
      .c19 {
        background-color: #ffffff;
        max-width: 481.9pt;
        padding: 56.7pt 56.7pt 56.7pt 56.7pt;
      }
      .c17 {
        font-weight: 700;
        /* font-size: 20pt; */
      }
      .c2 {
        padding: 0;
        margin: 0;
      }
      .c20 {
        font-weight: 700;
        /* font-size: 10.5pt; */
      }
      .c9 {
        /* font-size: 11pt; */
        font-weight: 700;
      }
      .c18 {
        height: 10.5pt;
      }
      .c6 {
        margin-left: 36pt;
      }
      .c10 {
        margin-left: 27pt;
      }
      .c14 {
        margin-left: 25.5pt;
      }
      .c15 {
        margin-left: 35.4pt;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        /* font-size: 36pt; */
        padding-bottom: 6pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        /* font-size: 24pt; */
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        ${tw`ml-2 mb-3`}
      }
      p {
        ${tw`mt-2 leading-loose`}
      }
      h1 {
        ${tw`text-3xl font-bold mt-10`}
      }
      h2 {
        ${tw`text-2xl font-bold mt-4`}
        display: inline-block;
      }
      h3 {
        ${tw`text-xl font-bold mt-6`}
      }
      h4 {
        ${tw`text-lg font-bold mt-6`}
      }
      h5 {
        ${tw`text-base italic mt-6`}
      }
      .simple_list {
        ${tw`mt-5 mb-5`}
        list-style-type: initial;
      }
      .simple_list.long {
        li span {
          line-height: 1.5em;
        }
      }
      .italic {
        font-style: italic;
      }
      ol.title li {
        ${tw`ml-0`}
      }
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Nav />
      <Container>
        <Content>
          <Text>
            <div>
              <h1>
                <span class="c16 c20">NorthGrav - Match and Merge 2048</span>
              </h1>
              <h1>
                <span class="c16 c17">Privacy Policy</span>
              </h1>
            </div>
            <p class="c4">
              <span class="c1"></span>
            </p>
            <p class="c4">
              <span class="c1"></span>
            </p>
            <p class="c8">
              <span class="c1">
                Welcome to NorthGrav - Match and Merge 2048 (hereinafter the
                &ldquo;APP&rdquo;). This policy applies to you and ONE OMIR SOFTWARE
                SRL, the Developer of the APP, irrespective of your country of residence
                or location.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                This Policy describes our privacy practices in plain language, keeping
                legal and technical jargon to a minimum to make sure you understand the
                information we collect, why we collect it, how it is used and your
                choices regarding your information.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Types of data processed</h2>
              </li>
            </ol>
            <span class="c1">
              The person that is responsible for your information under this Privacy Policy (the &ldquo;data controller&rdquo;) is:
            </span>
            <span class="c1">
              <p class="c0"><span class="c1">Emil-Ioan Omir</span></p>
              <p class="c0" id="h.30j0zll"><span class="c1">Strada Closca 66</span></p>
              <p class="c0"><span class="c1">Agnita 555100, Romania</span></p>
              <p class="c0">
                <span class="c1">E-mail address: dev.emilomir@gmail.com</span>
              </p>
            </span>
            <p class="c8">
              <span class="c1">
                Welcome to our Privacy Policy, which explains how we collect, store,
                protect and share your personal information and with whom we share it.
                We recommend that you read this privacy policy together with our terms
                and conditions.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                While you use the APP and our digital products and services, we collect
                some information about you. In addition, you may choose to use the APP
                to share information with other users.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                If you live in a country in the European Economic Area (which includes
                the European Union) and any other encompassed country or territory
                (collectively referred to as the European Region), you must be at least
                13 years of age (or the age prescribed in your country, if higher) to
                register and use the APP.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Overview of processing operations</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                The following overview summarises the types of data processed and the
                purposes of their processing and refers to the data subjects.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Types of data processed</h2>
              </li>
            </ol>
            <ul class="simple_list">
              <li class="c0 c13 li-bullet-0"><span class="c1">Inventory data</span></li>
              <li class="c0 c13 li-bullet-0"><span class="c1">Content data</span></li>
              <li class="c0 c13 li-bullet-0"><span class="c1">Contact data</span></li>
              <li class="c0 c13 li-bullet-0">
                <span class="c1">Meta/communication data </span>
              </li>
              <li class="c0 c13 li-bullet-0"><span class="c1">Usage data</span></li>
              <li class="c0 c13 li-bullet-0"><span class="c1">Contract data </span></li>
            </ul>
            <ol class="title" start="1">
              <li class="">
                <h2>Purposes of the processing</h2>
              </li>
            </ol>
            <ul class="simple_list">
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Provision of the APP and user experience</span>
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Direct marketing</span>
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Contact requests and communication</span>
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Reach measurement</span>
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Security measures</span>
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1"
                  >Provision of contractual services and customer service</span
                >
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Administration and response to enquiries</span>
              </li>
            </ul>
            <ol class="title" start="1">
              <li class="">
                <h2>Relevant legal basis</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                In the following, we inform you about the legal basis of Romania`s Data
                Protection Law no. 190/2018 (DPL) and the General Data Protection
                Regulation (GDPR) on the basis of which we process personal data. If
                more specific legal bases apply in individual cases, we will inform you
                of these in the privacy policy.
              </span>
            </p>
            <ul class="simple_list long">
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Consent (Art. 6 para. 1 p. 1 lit. a. GDPR) - The data subject has
          given his/her consent to the processing of personal data relating to
          him/her for a specific purpose or purposes.</span>
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Performance of a contract and pre-contractual enquiries (Art. 6 para.
          1 p. 1 lit. b. GDPR) - Processing is necessary for the performance of
          a contract to which the data subject is a party or for the performance
          of pre-contractual measures carried out at the data subject&#39;s
          request.</span>
              </li>
              <li class="c0 c10 li-bullet-0">
                <span class="c1">Legitimate interests (Art. 6 para. 1 p. 1 lit. f. GDPR) - Processing
          is necessary for the purposes of the legitimate interests of the
          controller or a third party, unless such interests are overridden by
          the interests or fundamental rights and freedoms of the data subject
          which require the protection of personal data.</span>
              </li>
            </ul>
            <ol class="title" start="1">
              <li class="">
                <h2>Security measures</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We take appropriate technical and organisational measures in accordance
        with the law, taking into account the state of the art, the costs of
        implementation and the nature, scope, circumstances and purposes of the
        processing, as well as the different probabilities of occurrence and the
        level of threat to the rights and freedoms of natural persons, in order
        to ensure a level of protection appropriate to the risk.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                The measures include, in particular, ensuring the confidentiality,
        integrity and availability of data by controlling physical and
        electronic access to the data as well as access to, input of, disclosure
        of, assurance of availability of and segregation of the data. We also
        have procedures in place to ensure the exercise of data subjects&#39;
        rights, the deletion of data and responses to data compromise.
        Furthermore, we already take the protection of personal data into
        account in the development or selection of hardware, software and
        procedures in accordance with the principle of data protection, through
        technology design and through data protection-friendly default
        settings.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                However, you must understand that no web site or Internet transmission
        is completely secure. Therefore, we can never guarantee that
        unauthorised access, hacking, data loss and other incidents can be
        completely excluded.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Your rights</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                The data protection laws in your country may give you the following
        rights:
              </span>
            </p>
            <ul class="simple_list long">
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1">Right to information:</span
        ><span class="c1"
          >&nbsp;what personal data a company processes and why (this
          notice).</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1">Right of access:</span
        ><span class="c1"
          >&nbsp;You can request information about data collected.</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1">Right to rectification:</span
        ><span class="c1"
          >&nbsp;If data collected is not correct, you can ask for it to be
          corrected.</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1">Right to erasure:</span
        ><span class="c1"
          >&nbsp;Under certain circumstances, you can request the erasure of
          your data.</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1">Right to restriction of processing:</span
        ><span class="c1"
          >&nbsp;In certain circumstances, you can request the further
          processing of your data, but the data will remain stored.</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1">Right to data portability:</span
        ><span class="c1"
          >&nbsp;You can have the data collected about you transferred to
          another provider in a machine-readable format.</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1">Right to object:</span
        ><span class="c1"
          >&nbsp;In certain circumstances (including where your data is
          processed on the basis of legitimate interests or for marketing
          purposes) you may object to processing.</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="italic c1"
          >Rights in relation to automated case-by-case decisions, including
          profiling: </span
        ><span class="c1"
          >This includes several rights where data is processed solely by
          automated means, and this has a legal or significant impact on an
          individual. In these circumstances, you have, among other things, the
          right to human intervention in the decision-making process.</span
        >
      </li>
    </ul>
            <p class="c8">
              <span class="c1">
                If you wish to exercise any of the rights listed above, you can contact
        us by email at dev.emilomir@gmail.com. For your protection and the
        protection of all our users, we may need to request certain information
        from you to help us confirm your identity before we can respond to the
        above requests.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                If you feel that we have not resolved your concern, you have the right
        to make a complaint at any time. You can also contact your local data
        protection supervisory authority.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>General app accesses</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                As with every server request, information such as IP address, user
        agent etc. is transmitted and stored anonymously in the server log for
        30 days. The provision of personal data is neither legally nor
        contractually required, nor is it necessary for the conclusion of a
        contract. You are also not obliged to provide the personal data.
        However, failure to provide the data could result in you not being able
        to use our APP or not being able to use it to its full extent. The legal
        basis for this data processing is consent.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Network access</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                The legal basis for this data processing is contract. Your data will be
        treated confidentially by us and deleted if you revoke the rights to use
        it or it is no longer required to provide the services and there is no
        legal obligation to retain it. The provision of personal data is
        necessary if you wish to make full use of our APP. However, failure to
        provide this data could result in you not being able to use our APP or
        not being able to use it to its full extent.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Installation of our APP</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                Our APP can be downloaded from the APP stores &quot;Google
        PlayStore&quot; and &quot;Apple App Store&quot;. Downloading our APP may
        require prior registration with the respective APP store and
        installation of the APP store software.
              </span>
            </p>
            <p class="c8">
              <span class="c1 italic">
                <h4>APP installation via the Google PlayStore</h4>
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                You can use the Google service &quot;Google Play&quot; of Google
        Ireland Limited (&quot;Google&quot;), Gordon House, Barrow Street,
        Dublin 4, Ireland, to install our APP.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                As far as we are aware, Google collects and processes the following
        data:
              </span>
            </p>
            <ul class="simple_list">
              <li class="c0 c5 li-bullet-0"><span class="c1">License check,</span></li>
              <li class="c0 c5 li-bullet-0"><span class="c1">network access,</span></li>
              <li class="c0 c5 li-bullet-0">
                <span class="c1">network connection,</span>
              </li>
              <li class="c0 c5 li-bullet-0">
                <span class="c1">WLAN connections,</span>
              </li>
              <li class="c0 c5 li-bullet-0">
                <span class="c1">location information,</span>
              </li>
            </ul>
            <p class="c8">
              <span class="c1">
                It cannot be ruled out that Google also transmits the information to a
        server in a third country. We cannot influence which personal data
        Google processes with your registration and the provision of downloads
        in the respective app store and app store software. The responsible
        party in this respect is solely Google as the operator of the Google
        Play Store. You can find more detailed information in Google&#39;s
        privacy policy, which you can access here:&nbsp;<a
          class="c17"
          href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1647450304620442&amp;usg=AOvVaw0XuKjYn7qVv4XbaTDIV6Ic"
          >https://policies.google.com/privacy</a
        >
              </span>
            </p>
            <p class="c8">
              <span class="c1 italic">
                <h4>APP installation via the Apple App Store</h4>
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                You can use the Apple service &quot;App Store&quot; a service of Apple
        Distribution International Ltd, Hollyhill Industrial Estate, Hollyhill
        Ln, Knocknaheeney, Cork, Ireland, to install our app.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                As far as we are aware, Apple collects and processes the following
        data:
              </span>
            </p>
            <ul class="simple_list">
              <li class="c0 c5 li-bullet-0">
                <span class="c1">device identifiers,</span>
              </li>
              <li class="c0 c5 li-bullet-0"><span class="c1">IP addresses,</span></li>
              <li class="c0 c5 li-bullet-0">
                <span class="c1">location information,</span>
              </li>
            </ul>
            <p class="c8">
              <span class="c1">
                It cannot be excluded that Apple also transmits the information to a
        server in a third country. This could in particular be Apple Inc. One
        Apple Park Way, Cupertino, California, USA, 95014. We cannot influence
        which personal data Apple processes with your registration and the
        provision of downloads in the respective app store and app store
        software. The responsible party in this respect is solely Apple as the
        operator of the Apple App Store. You can find more detailed information
        in Apple&#39;s privacy policy, which you can access here: 
        <a class="c17" href="https://www.google.com/url?q=https://www.apple.com/legal/privacy/&amp;sa=D&amp;source=editors&amp;ust=1647450304621722&amp;usg=AOvVaw3Qz7T9uqpleTSXPxJ6oHrD">https://www.apple.com/legal/privacy/</a>
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Contacting Us</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                If you contact us and send us general enquiries the contact details you
        provide, will be stored and used by us to fulfil the purpose associated
        with the transmission, e.g., to process your enquiry or in the event of
        follow-up questions.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                The basis for this storage and use of your personal data is your
        consent which you give us by sending the contact form. Insofar as you
        provide us with your personal data for the purpose of responding to your
        questions, the entry of personal data is required as without this
        information, we cannot process your request.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                You have the right to revoke your consent to the data processing
        described above at any time with effect for the future. In this case, we
        will no longer process your data. Your personal data will be deleted
        even without your revocation in any case if we have processed your
        request or if the storage is inadmissible for other legal reasons.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Push messages</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                When using the app, you will receive so-called push messages from us,
        even if you are not currently using the app. These are messages that we
        send you as part of the performance of the contract, but also
        promotional information. You can adjust or stop receiving push messages
        at any time via the device settings of your end device.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Device information</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We collect information from and about the device(s) you use to access
        our APP, including hardware and software information such as IP address,
        device ID and type, device-specific and app settings and properties, APP
        crashes, advertising IDs (such as Google&#39;s AAID and Apple&#39;s
        IDFA, both randomly generated numbers, information about your wireless
        and mobile network connection such as your service provider and signal
        strength; information about device sensors such as accelerometer,
        gyroscope and compass.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>How we share information</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                Our goal is to help you connect with people. User information is, of
        course, mainly shared with other users. We also share some user
        information with service providers and partners who help us operate the
        Services, and in some cases with legal authorities.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>With our service providers and partners</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We use third parties to help us operate and improve our services. These
        third parties assist us with various tasks, including data hosting and
        maintenance, analytics, customer support, marketing, advertising,
        payment processing, and security measures.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                We may also share information with partners who distribute our services
        and assist us with advertising. For example, we may share limited
        information about you in hashed, non-human readable form with
        advertising partners.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                We follow a rigorous vetting process before engaging a service provider
        or working with a partner. All our service providers and partners should
        commit to strict confidentiality.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h5>For corporate transactions</h5>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We may transfer your information if we are involved in whole or in part
        in a merger, sale, acquisition, divestiture, restructuring,
        reorganization, dissolution, bankruptcy or other change of ownership or
        control.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h5>When required by law</h5>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We may disclose your information if reasonably necessary: (i) to comply
        with a legal process, such as a court order, subpoena or search warrant,
        government/legal investigation, or other legal requirement; (ii) to
        assist in the prevention or detection of crime (in each case, subject to
        applicable law); or (iii) to protect the safety of any person.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h5>To enforce legal rights</h5>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We may also disclose information: (i) if disclosure would reduce our
        liability in actual or threatened litigation; (ii) if necessary to
        protect our legal rights and the legal rights of our users, business
        partners or other interested parties; (iii) to enforce our agreements
        with you; and (iv) to investigate, prevent, or take other action
        regarding illegal activities, suspected fraud or other misconduct.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h5>With your consent or at your request</h5>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We may ask for your consent to share your information with third
        parties. In any such case, we will make clear why we want to share the
        information.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                We may use and share non-personal information such as device
        information, general demographic information, general behavioural
        information, geolocation in de-identified form, and personal information
        in hashed, non-human readable form in the above circumstances. We may
        also share this information with third parties (including, without
        limitation, advertisers) to develop and deliver targeted advertisements
        on our Services and on third party websites or applications and to
        analyse and report on the advertisements you see. We may combine this
        data with additional non-personal data or personal data in hashed,
        non-human readable form collected from other sources.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Unity</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                Our APP is built using the game development platform Unity (operated by
        Unity Technologies, 30 3rd Street, San Francisco, CA 94103, United
        States). This uses software modules and libraries that come from Unity
        Technologies itself or third parties and become an inherent part of our
        Apps (called a game engine). During the execution of the app, personal
        data may be processed by Unity itself, including device identification
        numbers (so-called UDID - unique device identifiers, IP address). Data
        such as the device type, country of app installation, advertising ID,
        sensor markings (e.g., from the pressure or acceleration sensor), game
        identification number (app ID) and the checksum of the transmitted data
        are also processed.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                Using Unity also means that the services are using &quot;Unity
        Analytics&quot;. Unity Analytics collect the personal data such as IP
        addresses and device identification number (so-called UDID - unique
        device identifiers). Other data collected includes device specifications
        (device type, device language), usage data, information about in-app
        behaviour and in-app purchases, custom event data (where applicable).
        Through Unity Analytics, we can find out which app ads are tapped or
        clicked on from your device.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                We do not have access to individual IP addresses or device numbers. You
        can find more information about Unity Technologies&#39; privacy policy &nbsp;
        <a
          class="c17"
          href="https://www.google.com/url?q=https://unity3d.com/en/legal/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1647450304626526&amp;usg=AOvVaw1PhNBrP7LbfX46bc1oz_4t"
          >here</a>
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Firebase</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                The APP uses the&nbsp;
                <a class="c17" href="https://www.google.com/url?q=https://firebase.google.com/support/privacy&amp;sa=D&amp;source=editors&amp;ust=1647450304627514&amp;usg=AOvVaw0pGanlGbjtC4cvCa4tSzub"
                >Firebase</a>
                &nbsp;tool, which is part of the Firebase platform of Google Inc, 1600
        Amphitheatre Parkway Mountain View, CA 94043, USA, to obtain statistics
        on how the APP is used, in particular active user numbers, session
        length, stability rating and storage time. Answers logs the use of the
        APP, and we evaluate user behaviour and user activity in general, i.e.,
        not on a personal basis.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                For this purpose, the following data is transferred to the Analytics
        Engine: name and APPStore ID, build version, individual device
        installation key (e.g. IDFA [iOS], Advertising ID, and Android ID),
        timestamp, device model, device name, device operating system name and
        version numbers, the language and country settings of the device (iOS),
        the number of CPU cores on the device (iOS), whether a device has the
        status &quot;jailbreak&quot; (iOS) or &quot;root &quot; (Android), APP
        lifecycle events (iOS) and APP activities (Android);
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                The legal basis for this data processing is our legitimate interest.
        The data collected via Google will be deleted after 6 months at the
        latest.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Crashlytics</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                The APP uses the&nbsp;
                <a class="c17" href="https://www.google.com/url?q=https://firebase.google.com/support/privacy&amp;sa=D&amp;source=editors&amp;ust=1647450304629132&amp;usg=AOvVaw0HpvvtkSqOvI4g2MZ_mU6H"
                >Crashlytics</a>
                , which is part of the platform Firebase of Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, to log crashes of
        the APP. No personal data is transmitted. Only real-time crash reports
        with precise details of code locations and device information are sent,
        which is intended to simplify maintenance and improve the resulting
        stability of the APP. The legal basis for data processing is our
        legitimate interest.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Authorisations and Access</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                We may request access or permission to certain functions from your
        mobile device (Internet, vibration, billing, accelerometer, and Push
        notifications). The legal basis for data processing is our legitimate
        interest and the provision of contractual or pre-contractual measures.
        You can your permissions at any time via Settings (iOS) or Settings Menu
        (Android).
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Uninstall</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                You can stop the collection of information by the APP by uninstalling
        it using the standard uninstall procedure for your device. When you
        uninstall the APP from your mobile device, the unique identifier
        associated with your device will still be stored. If you reinstall the
        APP on the same mobile device, we may again associate that identifier
        with your previous transactions and activities.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Storage period</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                Unless a more specific retention period is stated within this privacy
        policy, we will retain your personal data until the purpose for
        processing it no longer applies. If you assert a legitimate request for
        deletion or revoke your consent to data processing, your data will be
        deleted unless we have other legally permissible reasons for storing
        your personal data (e.g. retention periods under tax or commercial law);
        in the latter case, the data will be deleted once these reasons no
        longer apply.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Note on data transfer to the USA</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                Among other things, tools from companies based in the USA are
        integrated in our APP. If these tools are active, your personal data may
        be transferred to the US servers of the respective companies. We would
        like to point out that the USA is not a safe third country in the sense
        of EU data protection law. US companies are obliged to hand over
        personal data to security authorities without you as a data subject
        being able to take legal action against this. It can therefore not be
        ruled out that US authorities (e.g., intelligence services) process,
        evaluate and permanently store your data located on US servers for
        monitoring purposes. We have no influence on these processing
        activities.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Data Breaches/Notification</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                Databases or data sets that include Personal Data may be breached
        inadvertently or through wrongful intrusion. Upon becoming aware of a
        data breach, we will notify all affected individuals whose Personal Data
        may have been compromised, and the notice will be accompanied by a
        description of action being taken to reconcile any damage as a result of
        the data breach. Notices will be provided as expeditiously as possible
        after which the breach was discovered.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Updating your information</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                If you believe that the information, we hold about you is inaccurate or
        that we are no longer entitled to use it and want to request its
        rectification, deletion, or object to its processing, please contact us.
        For your protection and the protection of all of our users, we may ask
        you to provide proof of identity before we can answer the above
        requests.
              </span>
            </p>
            <p class="c8">
              <span class="c1">
                Keep in mind, we may reject requests for certain reasons, including if
        the request is unlawful or if it may infringe on trade secrets or
        intellectual property or the privacy of another user. Also, we may not
        be able to accommodate certain requests to object to the processing of
        personal information, notably where such requests would not allow us to
        provide our service to you anymore.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Obligation to provide personal data</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                You are not obliged to provide us with personal data. However,
        depending on the individual case, the provision of certain personal data
        may be necessary for the provision of the above services. If you do not
        provide us with this personal data, we may not be able to provide the
        service.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Changes</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                This policy and our commitment to protecting the privacy of your
        personal data can result in changes to this policy. Please regularly
        review this policy to keep up to date with any changes.
              </span>
            </p>
            <ol class="title" start="1">
              <li class="">
                <h2>Queries and Complaints</h2>
              </li>
            </ol>
            <p class="c8">
              <span class="c1">
                Any comments or queries on this policy should be directed to us. If you
        believe that we have not complied with this policy or acted otherwise
        than in accordance with data protection law, then you should notify
        us.
              </span>
            </p>
          </Text>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};