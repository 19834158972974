import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl, Content2Xl } from "components/misc/Layouts";
import { LogoLink } from "components/headers/light.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import tw from "twin.macro";
import styled from "styled-components";

import Nav from "custom/Nav.js";

const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const Content = tw.div`mt-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  .lst-kix_fl5tgqqoyc2d-8 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-8;
      }
      ol.lst-kix_fl5tgqqoyc2d-7.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-7 0;
      }
      .lst-kix_19gmnee74sij-7 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-7, lower-roman) ". ";
      }
      ol.lst-kix_vg07ombeoyvo-3.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-3 0;
      }
      ol.lst-kix_i5ei47vkpwoc-2.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-2 0;
      }
      .lst-kix_19gmnee74sij-6 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-6, lower-latin) ". ";
      }
      .lst-kix_19gmnee74sij-3 > li:before {
        content: "(" counter(lst-ctn-kix_19gmnee74sij-3, lower-latin) ") ";
      }
      .lst-kix_19gmnee74sij-5 > li:before {
        content: "(" counter(lst-ctn-kix_19gmnee74sij-5, decimal) ") ";
      }
      .lst-kix_kn8ag28tizsn-0 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-0;
      }
      .lst-kix_19gmnee74sij-4 > li:before {
        content: "(" counter(lst-ctn-kix_19gmnee74sij-4, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-3 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-3;
      }
      ol.lst-kix_gs5dka6k8n6a-2.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-2 0;
      }
      ol.lst-kix_ahmacfe5zsdf-2.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-2 0;
      }
      .lst-kix_19gmnee74sij-1 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-1;
      }
      ol.lst-kix_19gmnee74sij-8.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-8 0;
      }
      .lst-kix_vg07ombeoyvo-7 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-7;
      }
      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0;
      }
      .lst-kix_19gmnee74sij-8 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-8, decimal) ". ";
      }
      .lst-kix_gs5dka6k8n6a-2 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-2;
      }
      .lst-kix_fl5tgqqoyc2d-0 > li:before {
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-0, lower-latin) ") ";
      }
      ol.lst-kix_i5ei47vkpwoc-8.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-8 0;
      }
      .lst-kix_fl5tgqqoyc2d-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-1, lower-roman) ") ";
      }
      .lst-kix_kn8ag28tizsn-2 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-2;
      }
      .lst-kix_i5ei47vkpwoc-7 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-7;
      }
      .lst-kix_ahmacfe5zsdf-5 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-5;
      }
      .lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2;
      }
      .lst-kix_gs5dka6k8n6a-4 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-4;
      }
      ol.lst-kix_gs5dka6k8n6a-8.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-8 0;
      }
      ol.lst-kix_kn8ag28tizsn-8.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-8 0;
      }
      .lst-kix_19gmnee74sij-3 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-3;
      }
      .lst-kix_vg07ombeoyvo-3 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-3;
      }
      .lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4;
      }
      .lst-kix_i5ei47vkpwoc-0 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-0;
      }
      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0;
      }
      .lst-kix_kn8ag28tizsn-4 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-4;
      }
      .lst-kix_fl5tgqqoyc2d-4 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-4;
      }
      ol.lst-kix_19gmnee74sij-3.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-3 0;
      }
      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0;
      }
      .lst-kix_gs5dka6k8n6a-8 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-8;
      }
      ol.lst-kix_i5ei47vkpwoc-3.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-3 0;
      }
      ol.lst-kix_19gmnee74sij-2.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-2 0;
      }
      .lst-kix_19gmnee74sij-1 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-1, lower-roman) ") ";
      }
      .lst-kix_19gmnee74sij-2 > li:before {
        content: "" counter(lst-ctn-kix_19gmnee74sij-2, decimal) ") ";
      }
      ol.lst-kix_gs5dka6k8n6a-3.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-3 0;
      }
      .lst-kix_19gmnee74sij-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        ${tw`text-sm  text-gray-800`}(lst-ctn-kix_19gmnee74sij-0, lower-latin) ") ";
      }
      ol.lst-kix_list_1-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-6 {
        list-style-type: none;
      }
      .lst-kix_fl5tgqqoyc2d-2 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-2;
      }
      ol.lst-kix_kn8ag28tizsn-4.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-4 0;
      }
      .lst-kix_kn8ag28tizsn-6 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-6;
      }
      ol.lst-kix_list_1-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-1 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-6.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-6 0;
      }
      ol.lst-kix_list_1-2 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-1.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-1 0;
      }
      .lst-kix_i5ei47vkpwoc-4 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-4;
      }
      .lst-kix_kn8ag28tizsn-7 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-7;
      }
      ol.lst-kix_kn8ag28tizsn-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-8 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-3 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-2 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-1 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-4.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-4 0;
      }
      ol.lst-kix_kn8ag28tizsn-0 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-7 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-6.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-6 0;
      }
      ol.lst-kix_kn8ag28tizsn-6 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-5 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-4 {
        list-style-type: none;
      }
      .lst-kix_i5ei47vkpwoc-5 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-5;
      }
      .lst-kix_kn8ag28tizsn-5 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-5;
      }
      .lst-kix_ahmacfe5zsdf-8 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-8;
      }
      .lst-kix_ahmacfe5zsdf-0 > li {
        ${tw`text-sm  text-gray-800`}
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-0;
      }
      ol.lst-kix_ahmacfe5zsdf-3.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-3 0;
      }
      .lst-kix_vg07ombeoyvo-1 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-1;
      }
      ol.lst-kix_kn8ag28tizsn-1.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-1 0;
      }
      .lst-kix_gs5dka6k8n6a-7 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-7;
      }
      .lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8;
      }
      ol.lst-kix_vg07ombeoyvo-1.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-1 0;
      }
      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0;
      }
      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0;
      }
      .lst-kix_19gmnee74sij-6 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-6;
      }
      ol.lst-kix_i5ei47vkpwoc-1.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-1 0;
      }
      ol.lst-kix_19gmnee74sij-5 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-0, lower-latin) ") ";
      }
      ol.lst-kix_19gmnee74sij-4 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-7 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-6 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-1 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-2, decimal) ") ";
      }
      ol.lst-kix_19gmnee74sij-0 {
        list-style-type: none;
      }
      .lst-kix_ahmacfe5zsdf-7 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-7;
      }
      ol.lst-kix_19gmnee74sij-3 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-2.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-2 0;
      }
      ol.lst-kix_19gmnee74sij-2 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-6 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-6;
      }
      ol.lst-kix_19gmnee74sij-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0;
      }
      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0;
      }
      .lst-kix_fl5tgqqoyc2d-5 > li:before {
        content: "(" counter(lst-ctn-kix_fl5tgqqoyc2d-5, decimal) ") ";
      }
      .lst-kix_19gmnee74sij-5 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-5;
      }
      ol.lst-kix_ahmacfe5zsdf-0.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-0 0;
      }
      ol.lst-kix_gs5dka6k8n6a-7.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-7 0;
      }
      .lst-kix_fl5tgqqoyc2d-3 > li:before {
        content: "(" counter(lst-ctn-kix_fl5tgqqoyc2d-3, lower-latin) ") ";
      }
      ol.lst-kix_i5ei47vkpwoc-0.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-0 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-8.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-8 0;
      }
      .lst-kix_list_1-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-1, decimal) ". ";
      }
      ol.lst-kix_kn8ag28tizsn-3.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-3 0;
      }
      .lst-kix_list_1-3 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
      }
      ol.lst-kix_vg07ombeoyvo-2.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-2 0;
      }
      .lst-kix_list_1-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-7, decimal) ". ";
      }
      .lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3;
      }
      .lst-kix_list_1-5 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-5, decimal) ". ";
      }
      .lst-kix_fl5tgqqoyc2d-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-7, lower-roman) ". ";
      }
      .lst-kix_gs5dka6k8n6a-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-8, decimal) ". ";
      }
      .lst-kix_fl5tgqqoyc2d-3 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-3;
      }
      .lst-kix_gs5dka6k8n6a-0 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-0;
      }
      .lst-kix_gs5dka6k8n6a-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-8 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-8;
      }
      .lst-kix_gs5dka6k8n6a-4 > li:before {
        content: "(" counter(lst-ctn-kix_gs5dka6k8n6a-4, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-1 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-1;
      }
      .lst-kix_vg07ombeoyvo-2 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-2;
      }
      ol.lst-kix_ahmacfe5zsdf-1.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-1 0;
      }
      .lst-kix_ahmacfe5zsdf-5 > li:before {
        content: "(" counter(lst-ctn-kix_ahmacfe5zsdf-5, decimal) ") ";
      }
      .lst-kix_ahmacfe5zsdf-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-7, lower-roman) ". ";
      }
      .lst-kix_kn8ag28tizsn-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-8, decimal) ". ";
      }
      .lst-kix_ahmacfe5zsdf-4 > li:before {
        content: "(" counter(lst-ctn-kix_ahmacfe5zsdf-4, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-8, decimal) ". ";
      }
      .lst-kix_gs5dka6k8n6a-3 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-3;
      }
      .lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1;
      }
      .lst-kix_ahmacfe5zsdf-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-1, lower-roman) ") ";
      }
      .lst-kix_ahmacfe5zsdf-3 > li:before {
        content: "(" counter(lst-ctn-kix_ahmacfe5zsdf-3, lower-latin) ") ";
      }
      .lst-kix_19gmnee74sij-2 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-2;
      }
      .lst-kix_vg07ombeoyvo-6 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-6;
      }
      .lst-kix_ahmacfe5zsdf-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-2, decimal) ") ";
      }
      .lst-kix_kn8ag28tizsn-1 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-1;
      }
      .lst-kix_kn8ag28tizsn-4 > li:before {
        content: "(" counter(lst-ctn-kix_kn8ag28tizsn-4, lower-roman) ") ";
      }
      .lst-kix_kn8ag28tizsn-3 > li:before {
        content: "(" counter(lst-ctn-kix_kn8ag28tizsn-3, lower-latin) ") ";
      }
      .lst-kix_kn8ag28tizsn-5 > li:before {
        content: "(" counter(lst-ctn-kix_kn8ag28tizsn-5, decimal) ") ";
      }
      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0;
      }
      .lst-kix_ahmacfe5zsdf-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-0, lower-latin) ") ";
      }
      .lst-kix_i5ei47vkpwoc-8 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-8;
      }
      ol.lst-kix_ahmacfe5zsdf-5.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-5 0;
      }
      .lst-kix_fl5tgqqoyc2d-7 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-7;
      }
      .lst-kix_kn8ag28tizsn-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-7, lower-roman) ". ";
      }
      .lst-kix_kn8ag28tizsn-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-6, lower-latin) ". ";
      }
      ol.lst-kix_i5ei47vkpwoc-5.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-5 0;
      }
      ol.lst-kix_vg07ombeoyvo-0.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-0 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-8 {
        list-style-type: none;
      }
      .lst-kix_19gmnee74sij-0 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-0;
      }
      ol.lst-kix_fl5tgqqoyc2d-7 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-6 {
        list-style-type: none;
      }
      .lst-kix_vg07ombeoyvo-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-8, decimal) ". ";
      }
      .lst-kix_vg07ombeoyvo-3 > li:before {
        content: "(" counter(lst-ctn-kix_vg07ombeoyvo-3, lower-latin) ") ";
      }
      .lst-kix_vg07ombeoyvo-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-7, lower-roman) ". ";
      }
      ol.lst-kix_fl5tgqqoyc2d-1 {
        list-style-type: none;
      }
      .lst-kix_ahmacfe5zsdf-2 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-2;
      }
      ol.lst-kix_fl5tgqqoyc2d-0 {
        list-style-type: none;
      }
      .lst-kix_kn8ag28tizsn-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-1, lower-roman) ") ";
      }
      ol.lst-kix_kn8ag28tizsn-5.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-5 0;
      }
      .lst-kix_vg07ombeoyvo-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-2, decimal) ") ";
      }
      .lst-kix_kn8ag28tizsn-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-2, decimal) ") ";
      }
      ol.lst-kix_fl5tgqqoyc2d-5 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-4 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-0.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-0 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-3 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-5.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-5 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-2 {
        list-style-type: none;
      }
      .lst-kix_vg07ombeoyvo-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-1, lower-roman) ") ";
      }
      .lst-kix_kn8ag28tizsn-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_kn8ag28tizsn-0, lower-latin) ") ";
      }
      .lst-kix_vg07ombeoyvo-4 > li:before {
        content: "(" counter(lst-ctn-kix_vg07ombeoyvo-4, lower-roman) ") ";
      }
      .lst-kix_vg07ombeoyvo-5 > li:before {
        content: "(" counter(lst-ctn-kix_vg07ombeoyvo-5, decimal) ") ";
      }
      ol.lst-kix_ahmacfe5zsdf-4.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-4 0;
      }
      .lst-kix_i5ei47vkpwoc-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-2, decimal) ") ";
      }
      .lst-kix_i5ei47vkpwoc-3 > li:before {
        content: "(" counter(lst-ctn-kix_i5ei47vkpwoc-3, lower-latin) ") ";
      }
      .lst-kix_fl5tgqqoyc2d-5 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-5;
      }
      .lst-kix_i5ei47vkpwoc-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-1, lower-roman) ") ";
      }
      .lst-kix_i5ei47vkpwoc-5 > li:before {
        content: "(" counter(lst-ctn-kix_i5ei47vkpwoc-5, decimal) ") ";
      }
      ol.lst-kix_gs5dka6k8n6a-4.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-4 0;
      }
      .lst-kix_19gmnee74sij-4 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-4;
      }
      ol.lst-kix_fl5tgqqoyc2d-5.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-5 0;
      }
      .lst-kix_i5ei47vkpwoc-4 > li:before {
        content: "(" counter(lst-ctn-kix_i5ei47vkpwoc-4, lower-roman) ") ";
      }
      .lst-kix_gs5dka6k8n6a-1 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-1;
      }
      .lst-kix_ahmacfe5zsdf-6 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-6;
      }
      .lst-kix_vg07ombeoyvo-4 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-4;
      }
      ol.lst-kix_vg07ombeoyvo-5.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-5 0;
      }
      .lst-kix_i5ei47vkpwoc-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-0, lower-latin) ") ";
      }
      ol.lst-kix_gs5dka6k8n6a-5 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-4 {
        list-style-type: none;
      }
      .lst-kix_gs5dka6k8n6a-5 > li {
        counter-increment: lst-ctn-kix_gs5dka6k8n6a-5;
      }
      ol.lst-kix_gs5dka6k8n6a-7 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-6 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-1 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-6 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-0 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-7 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-3 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-8 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-2 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-2 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-3 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-4 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-5 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-8 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-0 {
        list-style-type: none;
      }
      ol.lst-kix_ahmacfe5zsdf-1 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-0.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-0 0;
      }
      ol.lst-kix_kn8ag28tizsn-6.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-6 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-4.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-4 0;
      }
      ol.lst-kix_vg07ombeoyvo-6.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-6 0;
      }
      .lst-kix_i5ei47vkpwoc-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-6, lower-latin) ". ";
      }
      .lst-kix_i5ei47vkpwoc-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-7, lower-roman) ". ";
      }
      .lst-kix_i5ei47vkpwoc-3 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-3;
      }
      .lst-kix_i5ei47vkpwoc-6 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-6;
      }
      .lst-kix_i5ei47vkpwoc-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_i5ei47vkpwoc-8, decimal) ". ";
      }
      .lst-kix_ahmacfe5zsdf-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_ahmacfe5zsdf-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-0 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-0;
      }
      ol.lst-kix_fl5tgqqoyc2d-0.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-0 0;
      }
      ol.lst-kix_vg07ombeoyvo-0 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-1 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-2 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-3 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-4 {
        list-style-type: none;
      }
      ol.lst-kix_19gmnee74sij-4.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-4 0;
      }
      ol.lst-kix_vg07ombeoyvo-5 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-6 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-7 {
        list-style-type: none;
      }
      ol.lst-kix_vg07ombeoyvo-8 {
        list-style-type: none;
      }
      .lst-kix_19gmnee74sij-7 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-7;
      }
      .lst-kix_19gmnee74sij-8 > li {
        counter-increment: lst-ctn-kix_19gmnee74sij-8;
      }
      .lst-kix_fl5tgqqoyc2d-1 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-1;
      }
      ol.lst-kix_i5ei47vkpwoc-8 {
        list-style-type: none;
      }
      ol.lst-kix_kn8ag28tizsn-7.start {
        counter-reset: lst-ctn-kix_kn8ag28tizsn-7 0;
      }
      ol.lst-kix_i5ei47vkpwoc-6 {
        list-style-type: none;
      }
      ol.lst-kix_fl5tgqqoyc2d-3.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-3 0;
      }
      ol.lst-kix_i5ei47vkpwoc-7 {
        list-style-type: none;
      }
      .lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7;
      }
      ol.lst-kix_i5ei47vkpwoc-4 {
        list-style-type: none;
      }
      ol.lst-kix_i5ei47vkpwoc-5 {
        list-style-type: none;
      }
      .lst-kix_kn8ag28tizsn-8 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-8;
      }
      .lst-kix_i5ei47vkpwoc-2 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-2;
      }
      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0;
      }
      .lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5;
      }
      ol.lst-kix_vg07ombeoyvo-7.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-7 0;
      }
      ol.lst-kix_i5ei47vkpwoc-4.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-4 0;
      }
      ol.lst-kix_ahmacfe5zsdf-6.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-6 0;
      }
      ol.lst-kix_19gmnee74sij-7.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-7 0;
      }
      .lst-kix_gs5dka6k8n6a-1 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-1, lower-roman) ") ";
      }
      ol.lst-kix_i5ei47vkpwoc-7.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-7 0;
      }
      .lst-kix_gs5dka6k8n6a-3 > li:before {
        content: "(" counter(lst-ctn-kix_gs5dka6k8n6a-3, lower-latin) ") ";
      }
      .lst-kix_kn8ag28tizsn-3 > li {
        counter-increment: lst-ctn-kix_kn8ag28tizsn-3;
      }
      ol.lst-kix_19gmnee74sij-6.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-6 0;
      }
      ol.lst-kix_fl5tgqqoyc2d-2.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-2 0;
      }
      ol.lst-kix_gs5dka6k8n6a-1.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-1 0;
      }
      .lst-kix_i5ei47vkpwoc-1 > li {
        counter-increment: lst-ctn-kix_i5ei47vkpwoc-1;
      }
      ol.lst-kix_ahmacfe5zsdf-7.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-7 0;
      }
      .lst-kix_fl5tgqqoyc2d-0 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-0;
      }
      .lst-kix_fl5tgqqoyc2d-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-6, lower-latin) ". ";
      }
      .lst-kix_vg07ombeoyvo-0 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_vg07ombeoyvo-0, lower-latin) ") ";
      }

      .lst-kix_list_1-0.h2 > li:before {
        ${tw`text-2xl font-bold`}
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      .lst-kix_list_1-0.h3 > li:before {
        ${tw`text-xl font-bold`}
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      .lst-kix_list_1-0.span > li:before {
        ${tw`text-lg font-bold`}
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      ol.lst-kix_vg07ombeoyvo-8.start {
        counter-reset: lst-ctn-kix_vg07ombeoyvo-8 0;
      }
      .lst-kix_fl5tgqqoyc2d-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-2, decimal) ") ";
      }
      .lst-kix_fl5tgqqoyc2d-4 > li:before {
        content: "(" counter(lst-ctn-kix_fl5tgqqoyc2d-4, lower-roman) ") ";
      }
      .lst-kix_list_1-2 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-2, decimal) ". ";
      }
      ol.lst-kix_i5ei47vkpwoc-2 {
        list-style-type: none;
      }
      ol.lst-kix_i5ei47vkpwoc-3 {
        list-style-type: none;
      }
      .lst-kix_list_1-4 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-4, decimal) ". ";
      }
      ol.lst-kix_19gmnee74sij-5.start {
        counter-reset: lst-ctn-kix_19gmnee74sij-5 0;
      }
      ol.lst-kix_i5ei47vkpwoc-0 {
        list-style-type: none;
      }
      ol.lst-kix_i5ei47vkpwoc-1 {
        list-style-type: none;
      }
      ol.lst-kix_gs5dka6k8n6a-0.start {
        counter-reset: lst-ctn-kix_gs5dka6k8n6a-0 0;
      }
      .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0;
      }
      .lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6;
      }
      ol.lst-kix_ahmacfe5zsdf-8.start {
        counter-reset: lst-ctn-kix_ahmacfe5zsdf-8 0;
      }
      .lst-kix_list_1-6 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
      }
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }
      ol.lst-kix_fl5tgqqoyc2d-1.start {
        counter-reset: lst-ctn-kix_fl5tgqqoyc2d-1 0;
      }
      .lst-kix_fl5tgqqoyc2d-8 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_fl5tgqqoyc2d-8, decimal) ". ";
      }
      .lst-kix_fl5tgqqoyc2d-6 > li {
        counter-increment: lst-ctn-kix_fl5tgqqoyc2d-6;
      }
      .lst-kix_gs5dka6k8n6a-7 > li:before {
        ${tw`text-sm  text-gray-800`}
        content: "" counter(lst-ctn-kix_gs5dka6k8n6a-7, lower-roman) ". ";
      }
      ol.lst-kix_i5ei47vkpwoc-6.start {
        counter-reset: lst-ctn-kix_i5ei47vkpwoc-6 0;
      }
      .lst-kix_gs5dka6k8n6a-5 > li:before {
        content: "(" counter(lst-ctn-kix_gs5dka6k8n6a-5, decimal) ") ";
      }
      .lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, decimal) ". ";
      }
      .lst-kix_ahmacfe5zsdf-4 > li {
        counter-increment: lst-ctn-kix_ahmacfe5zsdf-4;
      }
      .lst-kix_vg07ombeoyvo-5 > li {
        counter-increment: lst-ctn-kix_vg07ombeoyvo-5;
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c0 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c3 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c5 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c4 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 10.5pt;
      }
      .c12 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10.5pt;
        font-style: normal;
      }
      .c13 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 10.5pt;
      }
      .c7 {
        padding-top: 0pt;
        text-indent: 36pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c11 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c8 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c16 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal;
      }
      .c19 {
        background-color: #ffffff;
        max-width: 481.9pt;
        padding: 56.7pt 56.7pt 56.7pt 56.7pt;
      }
      .c17 {
        font-weight: 700;
        /* font-size: 20pt; */
      }
      .c2 {
        padding: 0;
        margin: 0;
      }
      .c20 {
        font-weight: 700;
        /* font-size: 10.5pt; */
      }
      .c9 {
        /* font-size: 11pt; */
        font-weight: 700;
      }
      .c18 {
        height: 10.5pt;
      }
      .c6 {
        margin-left: 36pt;
      }
      .c10 {
        margin-left: 54pt;
      }
      .c14 {
        margin-left: 25.5pt;
      }
      .c15 {
        margin-left: 35.4pt;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        /* font-size: 36pt; */
        padding-bottom: 6pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        /* font-size: 24pt; */
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        ${tw`ml-2 mb-3`}
      }
      p {
        ${tw`mt-2 leading-loose`}
      }
      h1 {
        ${tw`text-3xl font-bold mt-10`}
      }
      h2 {
        ${tw`text-2xl font-bold`}
        display: inline-block;
      }
      h3 {
        ${tw`text-xl font-bold mt-6`}
      }
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Nav />
      <Container>
        <Content>
          {/* <div dangerouslySetInnerHTML={{
              __html: '<iframe id="policyFrame" src="privacy_policy.html" style="width: 100vw;height: 80vh;"></iframe>'
            }} /> */}
          <Text>
            <div>
              <h1>
                <span class="c16 c20">NorthGrav - Match and Merge 2048</span>
              </h1>
              <h1>
                <span class="c16 c17">Terms and Conditions</span>
              </h1>
            </div>
            <p class="c8">
              <span class="c1">
                This Agreement governs your use of our IOS and Android
                application NorthGrav - Match and Merge 2048 (hereinafter the
                &ldquo;APP&rdquo;) provided by ONE OMIR SOFTWARE SRL, the
                Developer of the APP of Strada Closca 66, Agnita 555100, Romania
                (hereinafter &quot;we&quot; or &ldquo;us&rdquo;).
              </span>
            </p>
            <p class="c4">
              <span class="c1"></span>
            </p>
            <p class="c8">
              <span class="c1">
                This Agreement sets out the basis on which we make the APP
                available to you (&ldquo;user&rdquo; or &ldquo;you&rdquo;) and
                on which you may use them. Our Privacy Policy forms an integral
                part of this Agreement. By installing or using the APP, you
                agree to accept and to be bound by (1) this Agreement and (2)
                our Privacy Policy, at all times. If you do not agree with one
                of these, please do not install or use the APP.{" "}
              </span>
            </p>
            <p class="c4">
              <span class="c1"></span>
            </p>
            <p class="c8">
              <span class="c1">
                If you have an Apple ID or Google Account, this Agreement shall
                coexist with, and shall not supersede, the applicable Terms of
                Use or Terms and Conditions. To the extent that the provisions
                of this Agreement conflict with the provisions of the Terms of
                Use or Terms and Conditions, the conflicting provisions in the
                Terms of Use or Terms and Conditions shall govern.
              </span>
            </p>
            <p class="c4">
              <span class="c1"></span>
            </p>
            <p class="c8">
              <span class="c1">
                We reserve the right to change, modify, add or delete articles
                in this Agreement at any time.
              </span>
            </p>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2 start" start="1">
              <li class="c5 li-bullet-0">
                <h2>Grant Of License</h2>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  We grant you a non-exclusive, non-transferable,
                  non-sublicensed, non-commercial and personal license to
                  install and/or use the APP (in whole or in part) (the
                  &ldquo;License&rdquo;), for such time until either you or we
                  terminate this Agreement.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You must in no event use, nor allow others to use, the APP or
                  this License for commercial purposes without obtaining a
                  license to do so from us.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  Updates, upgrades, patches and modifications may be necessary
                  in order to be able to continue to use the APP on certain
                  hardware.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <strong>THIS APP IS LICENSED TO YOU, NOT SOLD</strong>
                <span class="c1">.</span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  As applicable, certain parts of the APP may be using third
                  party features, some of which are managed by third-party
                  providers for which additional terms and/or costs may apply.
                  You must comply with such additional terms. Please review such
                  additional terms and costs carefully.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">You shall not, directly or indirectly </span>
              </li>
            </ol>
            <ol class="c2 lst-kix_kn8ag28tizsn-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span class="c1">
                  sell, rent out, lease, license, distribute, market, exploit
                  the APP or any of its parts commercially,{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  reverse engineer, decompile, disassemble, adapt, reproduce, or
                  create derivative works of this APP, in whole or in part;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  create, use and/or distribute &ldquo;auto&rdquo;,
                  &ldquo;script&rdquo; or &ldquo;macro&rdquo; computer programs
                  or other &ldquo;hack&rdquo; programs or software applications
                  for this APP;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  remove, alter, disable or circumvent any copyright and
                  trademark indications or other authorship and origin
                  information, notices or labels contained on or within this APP
                  and{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  export or re-export this APP or any copy of adaptation in
                  violation of any applicable laws or regulations.{" "}
                </span>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  While using the APP, you agree to comply with all applicable
                  laws, rules and regulations. In all cases, you may only use
                  the APP according to anticipated use of the APP. For example
                  purposes, and without limiting our rights to take action
                  against you, you may not:{" "}
                </span>
              </li>
            </ol>
            <ol class="c2 lst-kix_vg07ombeoyvo-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span class="c1">
                  create, use, share and/or publish by any means in relation to
                  the APP any material (text, words, images, etc.) which would
                  breach of a duty of confidentiality, infringe any intellectual
                  property right or an individual&rsquo;s right to privacy or
                  which would incite the committing of an unlawful act (in
                  particular, piracy, cracking or circulation of counterfeit
                  software);{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  modify, distort, block, abnormally burden, disrupt, slow down
                  and/or hinder the normal functioning of all or part of the
                  APP, or their accessibility to other users, or the functioning
                  of the partner networks of the APP, or attempt to do any of
                  the above;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  transmit or propagate any virus, Trojan horse, worm, bomb,
                  corrupted file and/or similar destructive device or corrupted
                  data in relation to the APP, and/or organise, participate in
                  or be involved in any way in an attack on our servers and/or
                  the APP and/or those of its service providers and partners;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  create, supply or use alternative methods of using the APP,
                  for example server emulators;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  transmitting or communicating any material or content which,
                  in our sole and exclusive discretion, is believed or deemed
                  offensive, including, but not limited to, language that is
                  harmful, threatening, unlawful, abusive, harassing,
                  defamatory, disparaging, obscene, sexually explicit, or
                  racially, ethnically, or otherwise objectionable;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  harassing or threatening any other users in the APP;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">make inappropriate use of the service; </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  falsely claim to be an employee or representative of us or our
                  partners and/or agents;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  falsely claim an endorsement in connection with the APP or
                  with us.{" "}
                </span>
              </li>
            </ol>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2" start="2">
              <li class="c5 li-bullet-0">
                <h2>Ownership</h2>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  All title, ownership rights and intellectual property rights
                  in and to the APP (including, without limitation, all text,
                  graphics, music or sounds, all messages or items of
                  information, fictional characters, names, themes, objects,
                  scenery, costumes, effects, dialogues, slogans, places,
                  characters, diagrams, concepts, videos, audio-visual effects,
                  domain names and any other elements which are part of the APP,
                  individually or in combination) and any and all copies thereof
                  are owned by us or our licensor&#39;s.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  The APP is protected by national and international laws,
                  copyright treaties and conventions and other laws.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  This APP may contain certain licensed materials, and, in that
                  event, our licensor&#39;s may protect their rights in the
                  event of any violation of this Agreement, any reproduction or
                  representation of these licensed materials in any way and for
                  any reason is prohibited without our prior permission and, if
                  applicable, our licensor&#39;s&rsquo; and
                  representatives&rsquo;.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  Except as expressly set forth in this Agreement, all rights
                  not granted hereunder to you are expressly reserved by us.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  This License confers no title or ownership in the APP and
                  should not be construed as a sale of any rights in the APP.
                </span>
              </li>
            </ol>
            <p class="c4 c10">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 span" start="3">
              <li class="c5 li-bullet-0">
                <span class="c9 c16">Prohibited uses</span>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You may use our APP only for lawful purposes. You may not use
                  our APP{" "}
                </span>
              </li>
            </ol>
            <p class="c4 c10">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_ahmacfe5zsdf-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span class="c1">
                  in any way that breaches any applicable local or international
                  laws or regulations;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  in any way that is unlawful or fraudulent, or has any unlawful
                  or fraudulent purpose or effect;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  to send, knowingly receive, upload, download, use or re-use
                  any material which does not comply with our content standards
                  as set out in our prevailing terms and conditions as amended
                  from time to time; and{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  to knowingly transmit any data, send or upload any material
                  that contains viruses, Trojan horses, worms, time-bombs,
                  keystroke loggers, spyware, adware or any other harmful
                  programs or similar computer code designed to adversely affect
                  the operation of any computer software or hardware.
                </span>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">You also agree: </span>
              </li>
            </ol>
            <ol class="c2 lst-kix_19gmnee74sij-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span class="c1">
                  not to reproduce, duplicate, copy or re-sell any part of our
                  APP in contravention of the provisions of our Terms; and{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  not to access without authority, interfere with, damage or
                  disrupt:{" "}
                </span>
              </li>
            </ol>
            <p class="c7 c6">
              <span class="c1">c) &nbsp;any part of our APP; </span>
            </p>
            <p class="c7 c6">
              <span class="c1">
                d) &nbsp;any equipment or network on which our APP is stored;
                &nbsp;
              </span>
            </p>
            <p class="c6 c7">
              <span class="c1">
                e) any software used in the provision of our APP; or{" "}
              </span>
            </p>
            <p class="c7 c6">
              <span class="c1">
                f) any equipment or network or software owned or used by any
                third party.
              </span>
            </p>
            <ol class="c2 lst-kix_list_1-1" start="2">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  Except as expressly set out in this Agreement or as permitted
                  by any applicable law, you undertake:{" "}
                </span>
              </li>
            </ol>
            <ol class="c2 lst-kix_i5ei47vkpwoc-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span class="c1">
                  not to reproduce, copy, modify, adapt, translate, publish,
                  display, communicate, transmit, sell, exploit or use the whole
                  or any part of any Service, our APP or any of the contents
                  therein for any commercial or other purposes;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  not to disassemble, decompile, reverse-engineer or create
                  derivative works based on the whole or any part of the source
                  code of our APP nor attempt to do any such thing, or to
                  reproduce, display or otherwise provide access to the
                  Services, our APP or any of the contents therein, including
                  but not limited to framing, mirroring, linking, spidering,
                  scraping or any other technological means;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  not to provide or otherwise make available our APP in whole or
                  in part (including but not limited to program listings, object
                  and source program listings, object code and source code), in
                  any form to any person without prior written consent from us;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  to include our copyright notice on all entire and partial
                  copies you make of our APP on any medium;{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  to comply with all applicable technology control or export
                  laws and regulations; and{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  not to disrupt, disable, or otherwise impair the proper
                  working of the Services, our APP or our servers, such as
                  through hacking, cyber-attacks (including but not limited to
                  denial-of-service attacks), tampering or reprogramming
                </span>
              </li>
            </ol>
            <p class="c4 c10">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 span" start="4">
              <li class="c5 li-bullet-0">
                <span class="c16 c9">Intellectual property rights</span>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You acknowledge that all intellectual property rights in our
                  APP anywhere in the world belong to us, that rights in our APP
                  are licensed (not sold) to you, and that you have no rights
                  in, or to, our APP other than the right to use them in
                  accordance with these Terms.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  Any intellectual property rights in content uploaded by you to
                  our APP shall continue to belong to you or their respective
                  owners. You agree that you grant us a royalty-free and
                  non-exclusive license to use, reproduce, publish and display
                  such intellectual property rights for the purposes of
                  performing the Services, promotional purposes, internal
                  administrative purposes and any other purposes set out in
                  these Terms, including for the purpose of improving the
                  Services and our responses to users of the APP.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You acknowledge that you have no right to have access to our
                  APP in source code form.
                </span>
              </li>
            </ol>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 span" start="5">
              <li class="c5 li-bullet-0">
                <span class="c16 c9">Warranties</span>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  While we make all efforts to maintain the accuracy of the
                  information on our APP, we provide the Services, APP and all
                  Related Content on an &ldquo;as is&rdquo; and &ldquo;as
                  available&rdquo; basis, unless otherwise specified in writing.
                  We make no representations or warranties of any kind, express
                  or implied, as to the operation of any of the foregoing,
                  unless otherwise specified in writing.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  To the full extent permissible by law, we disclaim all
                  warranties, express or implied, relating to our APP or any
                  Services, including but not limited to implied warranties of
                  merchantability and fitness for a particular purpose. We do
                  not warrant that the Services, our APP, the Related Content,
                  or electronic communications sent by us are free of viruses or
                  other harmful components.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You expressly acknowledge that use of the APP is at your own
                  risk. To the fullest extent permissible under applicable law,
                  the APP is supplied on an &ldquo;as is&rdquo; And &ldquo;as
                  available&rdquo; basis.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  We and our licensor&#39;s, channel partners and associated
                  service providers do not make and hereby disclaim any
                  guarantees, conditions, warranties of any kind, express,
                  implied or statutory or other terms including as to: a) its
                  conformity, accuracy, currentness, completeness, reliability
                  or security b) its suitability for a particular use; c)
                  implied warranties of title, non-infringement; d) its market
                  value; or e) your satisfaction.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  We do not warrant that the APP will be uninterrupted or
                  error-free, that defects will be corrected, or that the APP is
                  free of viruses or other harmful components.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You assume all responsibility for selecting the APP to achieve
                  your intended results, and for the installation of, use of,
                  and results obtained from the APP.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  To the fullest extent permissible under applicable law, in no
                  event will we and our licensors, channel partners and
                  associated service providers be liable for loss or damage
                  suffered in connection with the use of the APP or any related
                  third-party service. This includes without limitation{" "}
                </span>
              </li>
            </ol>
            <ol class="c2 lst-kix_fl5tgqqoyc2d-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span class="c1">
                  all losses of any kind, whether in tort (including for
                  negligence or breach of statutory duty), contract,
                  misrepresentation (whether innocent or negligent) or
                  otherwise,{" "}
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">direct loss; </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">accidental loss, </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">incidental loss, </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">consequential loss, and </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">indirect loss.</span>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  Notwithstanding the aforementioned limitations of liability,
                  your sole remedy in the event of a dispute with us or our
                  licensor&#39;s, channel partners and associated service
                  providers is to cease to use the APP; and if applicable, seek
                  damages for your losses.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  For any addition purchased for use in the APP that would not
                  meet the applicable legal warranties, our liability is limited
                  to the refund (directly or indirectly through its channel
                  partners or associated service providers) of the price of such
                  addition.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  In no event are we or our affiliates, licensor&#39;s, channel
                  partners and associated service providers be liable for
                  damages in excess of any amount you have paid to us for the
                  APP during the twelve (12) months immediately prior to the
                  time your cause of action arose.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  Nothing in this section shall affect our liability for death
                  or personal injury arising from our negligence, for fraud or
                  fraudulent misrepresentation, nor any other liability which
                  cannot be excluded or limited under applicable law.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  For purposes of this section our licensor&#39;s, channel
                  partners and associated service providers are third party
                  beneficiaries to the limitations of liability specified herein
                  and they may enforce this Agreement against you.
                </span>
              </li>
            </ol>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2" start="6">
              <li class="c5 li-bullet-0">
                <h2>Indemnity</h2>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You are solely responsible for any damage caused to us and our
                  licensor&#39;s, channel partners and associated service
                  providers and subcontractors, other users of the APP or any
                  other individual or legal entity as a result of your violation
                  of this Agreement.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  You hereby agree to defend, indemnify and keep indemnified us
                  and our affiliates, their licensors, channel partners and
                  associated service providers and their subcontractors against
                  any claim or alleged claims, liabilities, losses damages and
                  all costs (including lawyers&rsquo; fees), directly or
                  indirectly attributable to your fault and/or resulting from a)
                  a violation of any provision of this Agreement or b) your use
                  or misuse of the APP. We reserve the right to take sole
                  responsibility, at its own expense, for conducting the defence
                  of any claim for which you agreed to indemnify us.{" "}
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  The provisions of this Section shall remain in force after
                  termination of this Agreement.
                </span>
              </li>
            </ol>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2" start="7">
              <li class="c5 li-bullet-0">
                <h2>Termination</h2>
              </li>
            </ol>
            <p class="c8 c6">
              <span class="c1">
                The Agreement is effective from the earlier of the date you
                download or use the APP, until terminated according to its
                terms. You and we may terminate this Agreement, at any time, for
                any reason. Termination by us will be effective upon{" "}
              </span>
            </p>
            <ol class="c2 lst-kix_gs5dka6k8n6a-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span class="c1">&nbsp;notice to you or </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">termination of your Account (if any) or </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c1">
                  at the time of our decision to discontinue offering and/or
                  supporting the APP.{" "}
                </span>
              </li>
            </ol>
            <p class="c8 c15">
              <span class="c1">
                This Agreement will terminate automatically if you fail to
                comply with any of the terms and conditions of this Agreement.
                Upon termination for any reason, you must immediately uninstall
                the APP and destroy all copies of the APP in your possession.
              </span>
            </p>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2" start="8">
              <li class="c5 li-bullet-0">
                <h2>Changes </h2>
              </li>
            </ol>
            <ol class="c2 lst-kix_list_1-1 start" start="1">
              <li class="c0 li-bullet-0">
                <span class="c1">
                  We reserve the right, in its sole and absolute discretion, to
                  revise, update, change, modify, add to, supplement, or delete
                  certain terms of this Agreement for security, legal, best
                  practice or regulatory reasons. Such changes will be effective
                  with or as applicable, without prior notice to you. You are
                  responsible for checking this Agreement periodically for
                  changes. If any future changes to this Agreement are
                  unacceptable to you or cause you to no longer be in agreement
                  or compliance with this Agreement, you may terminate this
                  Agreement in accordance with this Agreement and must
                  immediately uninstall the APP and destroy all copies of the
                  APP. Your continued use of the APP following any revision to
                  this Agreement constitutes your complete and irrevocable
                  acceptance of any and all such changes.
                </span>
              </li>
              <li class="c0 li-bullet-0">
                <span class="c1">
                  We may modify the APP for any reason or without any specific
                  reason, at any time and at its entire discretion, in
                  particular for technical reasons such as updates, maintenance
                  operations and/or resets to improve and/or optimize the APP.
                  You agree that the APP may install or download the
                  modifications automatically. You agree that we may stop to
                  support previous versions of the APP upon availability of an
                  updated version. Our channel partners and associated service
                  providers shall have no obligation to furnish any maintenance
                  or customer support with respect to the APP. We also reserve
                  the right to amend the Rules of Conduct set out to place
                  limits on the use of the APP.
                </span>
              </li>
            </ol>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2" start="9">
              <li class="c5 li-bullet-0">
                <h2>No Waiver</h2>
              </li>
            </ol>
            <p class="c8 c6">
              <span class="c1">
                In the event that any party to this Agreement fails to exercise
                any right or remedy contained herein, this shall not be
                construed as a waiver of that right or remedy.
              </span>
            </p>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2" start="10">
              <li class="c5 li-bullet-0">
                <h2>Previous Terms and Conditions</h2>
              </li>
            </ol>
            <p class="c8 c6">
              <span class="c1">
                In the event of any conflict between this Agreement and any
                prior versions thereof, the provisions of this Agreement shall
                prevail unless it is expressly stated otherwise.
              </span>
            </p>
            <p class="c4 c6">
              <span class="c1"></span>
            </p>
            <ol class="c2 lst-kix_list_1-0 h2" start="11">
              <li class="c5 li-bullet-0">
                <h2>Law and Jurisdiction</h2>
              </li>
            </ol>
            <p class="c8 c6">
              <span class="c1">
                This Agreement and the relationship between you and us shall be
                governed by and construed in accordance with the Law of Romania
                and we and you agree to submit to the exclusive jurisdiction of
                the Courts of Sibiu.
              </span>
            </p>
            <p class="c13 c14">
              <span class="c1"></span>
            </p>
            <p class="c13">
              <span class="c1"></span>
            </p>
          </Text>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};