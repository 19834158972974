import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithVerticalPadding, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as HandleIcon } from "images/handle-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

import groupImg from "images/custom/group.jpg";
import testerImg from "images/custom/tester.jpg";
import installImg from "images/custom/install.jpg";

import heroScreenshotImageSrc from "images/custom/banner.png";
import playStoreImageSrc from "images/custom/google-play-badge.png";
import logo from "images/logo.svg";
import useInView from "@owaiswiz/use-in-view";

import Nav from "custom/Nav.js";
import Footer from "custom/Footer.js";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
const HeroRow = tw(Row)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const TextColumn = tw(Column)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(HeadingBase)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

const ComponentsContainer = tw.div`mt-24`;
const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
const ComponentName = tw.h6`text-lg`;
const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

const PlayStoreLink = tw.a`w-64 mt-10`;

const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-80 h-24`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  // window.gtag("js", new Date());
  // window.gtag("config", "UA-45799926-9");


  return (
    <AnimationRevealPage disabled>
        <Nav />
      <ContentWithPaddingXl>
        <Heading>NorthGrav - Match and Merge 2048 Beta</Heading>
        <Description>
          Thank you for your interest in joining the beta release of my first game, NorthGrav - Match and Merge 2048.
        <br/><br />
          Please go though each of the following three easy steps to join the beta.
        </Description>
        <ThreeColumnContainer>
          <Column>
            <Card href="https://groups.google.com/g/northgrav---close-beta">
              <span className="imageContainer" css={null}>
                <img src={groupImg} alt="" css={null} />
              </span>
              <span className="title">Step 1</span>
              <span className="title">Join Beta Group</span>
              <p className="description">
                The first step to joining the beta is to join the Google Beta Group.
              </p>
              <p className="description">
                After clicking the link bellow find and press the <b>Join Group</b> button int the group page and come back here.
              </p>
              <span className="link">
                <span> Join here </span>
                <ArrowRightIcon className="icon" />
              </span>
            </Card>
          </Column>
          <Column>
            <Card href="https://play.google.com/apps/testing/com.EmilOmir.NorthGravNumbers">
              <span className="imageContainer" css={null}>
                <img src={testerImg} alt="" css={null} />
              </span>
              <span className="title">Step 2</span>
              <span className="title">Become a tester</span>
              <p className="description">
                Next, go to the game Play Store page and click the "Become a tester" button.
              </p>
              <span className="link">
                <span> Game store page </span>
                <ArrowRightIcon className="icon" />
              </span>
            </Card>
          </Column>
          <Column>
            <Card href="https://play.google.com/store/apps/details?id=com.EmilOmir.NorthGravNumbers">
              <span className="imageContainer" css={null}>
                <img src={installImg} alt="" css={null} />
              </span>
              <span className="title">Step 3</span>
              <span className="title">Install game</span>
              <p className="description">
                Finally install the game from the Play Store and enjoy.
              </p>
              <p className="description">
                Don't hesitate to give constructive feedback about the good and bad parts.
              </p>
              <span className="link">
                <span> Install </span>
                <ArrowRightIcon className="icon" />
              </span>
            </Card>
          </Column>
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <Footer />
    </AnimationRevealPage>
  );
};
