import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithVerticalPadding, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";

import Footer from "custom/Footer.js";

import shieldImage from "../images/custom/shield.svg";
import contractImage from "../images/custom/contract.svg";

import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import Nav from "custom/Nav.js";

const Heading = tw(HeadingBase)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;

const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2 px-6 flex`}
`;

const Card = styled.div.attrs({as: "a"})`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  min-width: 190px;
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;
export default () => (
  <AnimationRevealPage>
      <Nav />
      <Content2Xl>
        <Heading>NorthGrav - Match and Merge 2048</Heading>
        {/* <Description>
          Here you will find the Privacy Policy and Terms and Conditions of using the game.
        </Description> */}
         <TwoColumnContainer>
          {/* <Heading>Our Professional <span tw="text-primary-500">Services</span></Heading> */}
            <Column>
                <Card href="/privacy-policy">
                  <span className="imageContainer">
                    <img src={shieldImage} alt="" />
                  </span>
                  <span className="textContainer">
                    <span className="title">Privacy Policy</span>
                    <p className="description">
                      
                    </p>
                  </span>
                </Card>
            </Column>
            <Column>
              <Card href="/terms-and-conditions">
                <span className="imageContainer">
                  <img src={contractImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">Terms<br/>and<br/>Conditions</span>
                  <p className="description">
                    
                  </p>
                </span>
              </Card>
            </Column>
        </TwoColumnContainer>
      </Content2Xl>
    <Footer />
  </AnimationRevealPage>
);
